import React from 'react'
import Layout from '../layout/layout'
import SEO from "../layout/seo"

const antiHarassmentPolicy = () => {
    
    return (

        
        <Layout title="Anti-Harassment Policy" attr="anti"><div className="container" style={{
            padding: `5rem 0`
        }}><SEO title="Anti-Harassment Policy" />
            
            <p>Ignite Summit is dedicated to providing a harassment-free experience for everyone. We do not tolerate harassment of participants in any form. Participants violating these rules may be sanctioned or expelled, at the discretion of the event organizers.</p>
            <p>Harassment includes offensive verbal comments, deliberate intimidation, stalking, following, unwanted photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention. Participants asked to stop any harassing behaviour are expected to comply immediately.</p>
            <p>Sexual language and imagery will not be tolerated in any event venue, including talks.</p>
            <p>If a participant engages in harassing behaviour, the organizers may take any action they deem appropriate, including warning the offender or expulsion from the event. We expect participants to follow these rules at all event spaces and related social events.</p>
            <p>If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact a member of the event team immediately in event chat or by email.</p>
                <ul>
                    <li>You can contact the event team at devrel@gridgain.com</li>
                    <li>You can find the event team on the day at event’s chat</li>
                    <li>You can contact the Apache Conference Planning team at planners@apachecon.com</li>
                    <li>Organizers: +1 650 241 2281</li>
                </ul>


            </div></Layout>
        
    )
}

export default antiHarassmentPolicy